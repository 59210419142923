var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"reports-overview"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"2","md":"3","sm":"3"}},[_c('v-card',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.radioGroup),callback:function ($$v) {_vm.radioGroup=$$v},expression:"radioGroup"}},[_c('v-radio',{attrs:{"label":"Units","value":"total"}}),_c('v-radio',{attrs:{"label":"Rate","value":"interval"}}),_c('v-radio',{attrs:{"label":"Sales","value":"revenue"}}),_c('v-radio',{staticClass:"mt-2",attrs:{"label":"Cumulative Sales","value":"sales"}}),_c('v-radio',{staticClass:"mt-2",attrs:{"label":"Average Rate","value":"avgRate"}})],1),_c('div',{staticClass:"ml-2"},[_c('h3',[_vm._v("Filters")]),_c('div',{staticClass:"mt-4"},[_c('h5',[_vm._v("Sessions")]),_vm._l((_vm.sessions),function(session){return _c('div',{key:session.sessionId},[_c('v-checkbox',{attrs:{"disabled":_vm.brandClickedFirst || _vm.unitClickedFirst,"label":session.name,"value":session.sessionId},on:{"click":function($event){return _vm.sessionClicked(session.sessionId)}},model:{value:(_vm.filterModel.sessionIds),callback:function ($$v) {_vm.$set(_vm.filterModel, "sessionIds", $$v)},expression:"filterModel.sessionIds"}})],1)}),_c('h5',{staticClass:"mt-2"},[_vm._v("Brands")]),_vm._l((_vm.brands),function(brand){return _c('div',{key:brand.brandId},[_c('v-checkbox',{attrs:{"disabled":(_vm.sessionClickedFirst &&
                      _vm.filterModel.sessionIds &&
                      _vm.filterModel.sessionIds.length > 0) ||
                    _vm.unitClickedFirst,"label":brand.name,"value":brand.brandId},on:{"click":function($event){return _vm.brandClicked(brand.brandId)}},model:{value:(_vm.filterModel.brandIds),callback:function ($$v) {_vm.$set(_vm.filterModel, "brandIds", $$v)},expression:"filterModel.brandIds"}})],1)}),_c('h5',{staticClass:"mt-2"},[_vm._v("Units")]),_vm._l((_vm.kioskSessions),function(kioskSession){return _c('div',{key:kioskSession.kioskSessionId},[_c('v-checkbox',{attrs:{"label":kioskSession.kioskKioskCode,"value":kioskSession.kioskSessionId,"disabled":_vm.radioGroup == 'avgRate'},on:{"click":function($event){return _vm.unitClicked(kioskSession.kioskSessionId)}},model:{value:(_vm.filterModel.kioskSessionIds),callback:function ($$v) {_vm.$set(_vm.filterModel, "kioskSessionIds", $$v)},expression:"filterModel.kioskSessionIds"}})],1)}),_c('v-card-actions',[_c('base-primary-btn',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.applyFilters()}}},[_vm._v(" Apply Filters ")])],1)],2)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"9","sm":"8"}},[_c('deployment-pints-per-hour-graph',{staticClass:"mt-2",attrs:{"title":_vm.sessionClickedFirst
              ? 'by Session'
              : _vm.brandClickedFirst
              ? 'by Brand'
              : _vm.unitClickedFirst
              ? 'by Unit'
              : 'by Deployment',"filterTitle":_vm.radioGroup,"deploymentId":_vm.deploymentId,"pintsPerHour":_vm.pintsPerHour,"dataType":_vm.dataType},on:{"update:pintsPerHour":function($event){_vm.pintsPerHour=$event},"update:pints-per-hour":function($event){_vm.pintsPerHour=$event}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }